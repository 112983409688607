const en = {
    /************************
      Global/Multiple pages
     ************************/
    page_loading: `Loading...`,
    data_load_error: `Something went wrong! Please refresh the page to try again.`,
    show_less: `show less`,
    read_more: `read more`,
    error_404_title: `Oops!`,
    error_404_description: `We can't find the page you are looking for.<br />Try one of these:`,

    /************************
      Menu
     ************************/
    menu_language: `Español`,
    menu_login: `Login`,
    menu_email: `sales@clariondrones.com`,

    menu_home: `Home`,
    menu_courses: `Courses`,
    menu_teachers: `Teachers`,
    menu_events: `Training Events`,

    menu_more: `About`,
    menu_contact_blog: `Blog`,
    menu_contact_about: `About Us`,
    menu_contact_contact: `Contact Us`,

    menu_services: `Services`,
    menu_services_1: `Service 1`,
    menu_services_2: `Service 2`,
    menu_services_3: `Service 3`,

    /************************
      Home page banner/slider
     ************************/
    home_hero_1_h1: `To start flying your drone for work or for fun, you need to get certified by passing the Transport Canada exam.`,
    home_hero_1_h2: `Your Clarion team will help you:`,
    home_hero_1_p: `<ul><li> Pass the drone pilot exam with our exam prep training</li>
    <li>Our experts will get you ready to fly safely and expertly</li>
    <li>Start your career as a paid drone pilot</li></ul>`,
    home_hero_1_cta: `View courses`,

    home_hero_2_h1: `GET YOUR DRONE PILOT CERTIFICATE & LEARN TO FLY`,
    home_hero_2_h2: `Start making money with your drone today!<br />`,
    home_hero_2_p: `We have over <b>16,000 enrolments</b> into our online courses.<br />Join them & enrol today!`,
    home_hero_2_p2: `Trusted since 1978.`,
    home_hero_2_cta: `View courses`,

    home_hero_3_h1: `Heading 1 placeholder`,
    home_hero_3_h2: `Heading 2`,
    home_hero_3_p: `Description paragraph.`,
    home_hero_3_cta: `View courses`,

    home_what_we_do: `What We Do`,
    home_what_we_do_description: `
        <p>
            The difference between a good and a great company is having a clear strategy and the ability and the expertise to execute it. Since 1978, we firmly believe in this, we have built it into a methodology which allow us to deliver exceptional service. In addition to offering our own courses, we work with educational institutions, sUAS manufacturers, public safety organizations, and businesses to create custom training solutions.
        </p>`,
    home_what_we_do_1: `Online courses<br /><small>Convenience and flexibility</small>`,
    home_what_we_do_2: `On site training <br /><small>Effective instruction</small>`,
    home_what_we_do_3: `It's All About Integration`,

    home_what_we_do_1_description: `Online courses give students the opportunity to plan study time around the rest of their day, instead of the other way around. Students can study and work at their convenience. All the Transport Canada compliant knowledge you require from anywhere, at any time !`,
    home_what_we_do_2_description: `It's is not just lecturing or going through a series of overheads. The learner-centered approach to instruction ensures that our students meet the demands targeted by our clients and industry.`,
    home_what_we_do_3_description: `Your commercial mission demands a customized solution that’s bigger than its performance figures. We are systems integration experts which enable our carefully chosen Vtol, Rotary and Fixed Wing RPAS Aircraft to accomplish advanced multispectral and ultra-high resolution monitoring missions without breaking a sweat.`,

    home_easy_as_123: `Easy as 123`,
    home_easy_as_col_1: `STEP 1 - Online Drone Pilot Courses`,
    home_easy_as_col_2: `STEP 2 - Pass The Transport Canada online exams`,
    home_easy_as_col_3: `STEP 3 - Start Flying`,

    home_easy_as_col_1_description: `Take our <em>Basic</em> or <em>Advanced</em> online drone course packages, and learn to fly safely and confidently.<br />
    Our courses will get you ready to pass your Transport Canada drone pilot exams.
    `,
    home_easy_as_col_2_description: `<em>Basic</em> – Write the online exam with  Transport Canada.<br />
    <em>Advanced</em> – Write the online exam with Transport Canada  AND take your in-person flight review with one of our Canada-wide flight reviewers.`,
    home_easy_as_col_3_description: `Congratulations!  You are now a Transport Canada certified drone pilot.<br />
      Fly for fun or for work but most of all fly with confidence , safety and doing it legally.
    `,

    home_easy_as_CTA: `Get started`,
    home_cta_view_courses: `View Courses`,

    /************************
      Courses Page
     ************************/
    course_apply_button: `Enroll Now`,
    course_cancel_button: `Back`,
    course_more_button: `more...`,
    course_title: `Courses`,
    course_intro: `Take one of our courses and meet the Transport Canada training requirements.`,

    /*****************************
      Teachers List & Detail Page
     *****************************/
    teachers_title: `Teachers`,
    teachers_not_found_title: `Sorry!`,
    teachers_not_found_description: `We can't find the teacher you are looking for in our list.`,
    teachers_view_all_link: `View all`,
    teachers_back_to_list_link: `Back to list`,

    /************************
      Events Page
     ************************/
    events_title: `Training Events`,
    events_intro: `Upcoming training events.`,

    /************************
      Blog Page
     ************************/
    blog_title: `Blog`,
    blog_title_latest: `Featured Blogs`,
    blog_read_more: `Read more`,
    blog_cancel_button: "Back",

    /************************
      About Us
     ************************/
    about_heading: `About Us`,

    about_timeline_present_heading: `Present`,
    about_timeline_present_description:
        "We have been in the aviation industry for over 40 years. We work with aircraft avionics systems and Unmanned Aerial Vehicles (UAV) / Remotely Piloted Aircraft (RPA) systems for a diverse set of clientele operating in such areas as Search and Rescue (SAR), Agriculture, Security, Real Estate, Thermal inspections and more.<br /><br />Clarion Drone Academy Inc. offers guidance with regulatory requirements. We deliver training to meet government compliance. We look forward to serving you.",

    about_timeline_2012_heading: `2012`,
    about_timeline_2012_description:
        "About this time we had observed the emerging markets in Unmanned Aircraft Systems.<br /><br />Our clients had also noticed the changes in this section of aviation and were asking us for help.Helping our clients achieve success has always been the backbone of what we do.<br /><br />We started to work with our clients in the Unmanned Aerial Vehicles (UAV) / Remotely Piloted Aircraft (RPA) arena and have never looked back.",

    about_timeline_2009_heading: `2009`,
    about_timeline_2009_description:
        "By this time we had many clients around the globe installing our equipment in government recognized service centres.<br /><br />The international aviation bodies made a decision to advance the technology requirements even faster. We met that challenge and were the first to market with new technology that had never been designed let alone built before, and we still hold that honour.<br /><br />A lot has changed over the years but designing and building avionics systems is our niche. Working with Transport Canada, the FAA and also the European governments (EASA) has been our strength.",

    about_timeline_1994_heading: `1994`,
    about_timeline_1994_description:
        "By this time we had been building radio beacons and accessories for 16 years. We moved  into our next facility with the company’s continued success.  New technology changes came along and we were at the forefront of those changes.<br /><br />We remained in that facility until 2000.",

    about_timeline_1978_heading: `1978`,
    about_timeline_1978_description:
        "Started off manufacturing Emergency Locator Transmitters in 1978 under the approval of Transport Canada, and have been doing so ever since.",

    /************************
      Contact Us
     ************************/
    contact_heading: `Contact Us`,
    contact_description: `If you have any questions about our courses, please do not hesitate to contact us.`,

    contact_form_name_label: `Name`,
    contact_form_name_placeholder: `Enter your name`,

    contact_form_email_label: `Email`,
    contact_form_email_placeholder: `Enter your email address`,

    contact_form_subject_label: `Subject`,
    contact_form_subject_placeholder: `Enter subject`,

    contact_form_message_label: `Message`,
    contact_form_message_placeholder: `Enter message`,

    contact_form_blank_error: `You missed something here`,
    contact_form_button_send: `Send message`,
    contact_form_button_successful: `Sent successfully`,
    contact_form_error: `Sorry, something went wrong. Please try again later.`,
    contact_form_submitted_message: `Thank you! We'll be in touch soon.`,

    /************************
      Footer
     ************************/
    footer_brand_name: `Clarion Drone Academy Inc.`,

    footer_copyright: `© COPYRIGHT {{year}}. CLARION DRONE ACADEMY INC.`,

    footer_address_line1: `4025 Alsace Rd`,
    footer_address_line2: `Powassan, ON Canada`,
    footer_address_line3: `P0H 1Z0`,
    footer_login_link: `Client login`,

    /************************
      Footer - Mailchimp form
     ************************/
    mailchimp_heading: `Newsletter`,
    mailchimp_name: `Name`,
    mailchimp_email: `Email`,
    mailchimp_email_note: `We'll keep this in a secure place.`,
    mailchimp_submit: `Submit`,
    mailchimp_sending: `Sending...`,
    mailchimp_submit_error: `Oops, something went wrong. Please try again.`,
    mailchimp_submit_ok: `Thank you for subscribing`
};
export default en;
