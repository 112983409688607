import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./Testimonials.scss";

const Testimonials = () => (
    <Container>
        {/* <Row>
            <Col className="text-center">
                <h1 className="text-primary">UAV Training Testimonials</h1>
            </Col>
        </Row> */}
        <Row>
            <Col>
                <Carousel controls={false} className="c-testimonials" indicators={false}>
                    <Carousel.Item>
                        <Carousel.Caption>
                            <div className="testimonial text-light">
                                <p className="mb-2">
                                    I have to say that it’s really something how just a few months ago after watching
                                    manufacturers advertising and people’s videos on the net that I had the impression
                                    it was not that big of a deal to fly a drone around wherever. Now, my view of the
                                    air space above me no matter where I am has changed a lot because of what I’ve
                                    learned about it. I’ve gained a healthier respect for what goes on up there. I can
                                    only imagine what it must be like for a pilot of a plane, no matter the size to
                                    enter controlled air space, the responsibility is tremendous and to add to it all
                                    the threat of encountering a drone......not good, the same goes for the people in
                                    ATC.
                                </p>
                                <p>
                                    Thanks again, and thanks for the opportunity to take your courses as well as go to
                                    your website and study. An excellent way to broaden ones perspective.
                                </p>
                            </div>
                            <p className="text-dark my-4 font-italic">- Mike F.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    </Container>
);

export default Testimonials;
