import React, { Component } from "react";
import autoBind from "react-autobind";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { t } from "i18next";
import ScrollAnimation from "react-animate-on-scroll";
import ReactGA from "react-ga";
import utils from "../../utils";
import loading from "./../../Img/loading.svg";
import BlogTeaser from "../../Components/BlogTeaser";
import "./Blog.scss";

export default class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogs: null,
            error: false
        };
        autoBind(this);
    }

    componentDidMount() {
        // Fetch blogs
        utils.makeGetRequest(`/api/v1/blogs`, null, true).then(
            (response) => {
                this.setState({
                    blogs: response
                });
            },
            (rejected) => {
                this.setState({
                    error: true
                });
            }
        );
        // Don't trigger from home pahe embedding
        if (!this.props.max) {
            ReactGA.pageview("/blog/");
            utils.updatePageTitle(t("blog_title"));
            utils.scrollTo("root");
        }
    }

    render() {
        let errorOrLoading = null;
        if (!this.state.blogs && !this.state.error) {
            errorOrLoading = (
                <div className="text-center p-5 mx-auto">
                    <img src={loading} alt='{t("page_loading")}' style={{ width: "100px" }} />
                </div>
            );
        } else if (!this.state.blogs && this.state.error) {
            errorOrLoading = (
                <Col className="mt-3">
                    <Alert className="m-auto" variant="danger">
                        {t("data_load_error")}
                    </Alert>
                </Col>
            );
        }

        return (
            <React.Fragment>
                <main className="p-blog">
                    <Container className="contents">
                        <Row className="pt-4">
                            <Col className="text-center">
                                <h2 className="text-primary text-uppercase">
                                    {this.props.max !== undefined ? t("blog_title_latest") : t("blog_title")}
                                </h2>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center">
                            {this.state.blogs &&
                                !this.state.error &&
                                this.state.blogs
                                    .sort((a, b) => b.created - a.created)
                                    .map((post, i) =>
                                        this.props.max === undefined || i < this.props.max ? (
                                            <Col xs={12} sm={6} md={6} lg={4} className="row-eq-height mt-4" key={i}>
                                                <ScrollAnimation animateIn="bounceInUp">
                                                    <BlogTeaser key={post.id} blog={post} />
                                                </ScrollAnimation>
                                            </Col>
                                        ) : null
                                    )}
                            {errorOrLoading}
                        </Row>
                    </Container>
                </main>
            </React.Fragment>
        );
    }
}
