import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Companies.scss";
import CanadaDefence from "./../../Img/c-canada-defence.png";
import CBC from "./../../Img/c-cbc.jpg";
import Toronto from "./../../Img/c-city-toronto.svg";
import Enbridge from "./../../Img/c-enbridge.png";
import Mississauga from "./../../Img/c-mississauga.svg";
import Peel from "./../../Img/c-peel.png";
import Crawford from "./../../Img/c-crawford.png";
import Mohawk from "./../../Img/c-mohawk.png";
import Rcmp from "./../../Img/c-rcmp.jpg";
import Sault from "./../../Img/c-sault-college.png";
import Uwaterloo from "./../../Img/c-uwaterloo.png";
import ParksCanada from "./../../Img/c-parks-canada.png";
import NaturalResources from "./../../Img/c-ministry-natural-resources.png";
import Frontenac from "./../../Img/c-frontenac.png";
import GlobalMedic from "./../../Img/c-global-media.png";

const Companies = () => (
    <Container className="c-companies py-5">
        <Row>
            <Col className="text-center">
                <h2 className="text-primary text-uppercase">Clients We Have Partnered With</h2>
            </Col>
        </Row>
        <Row className="my-3 logos">
            <Col className="text-center d-flex">
                <img
                    className="align-self-center m-auto"
                    src={CanadaDefence}
                    alt="Canadian Department of defence"
                    title="Canadian Department of defence"
                />
            </Col>
            <Col className="text-center d-flex">
                <img src={Rcmp} className="align-self-center m-auto vertical" alt="RCMP" title="RCMP" />
            </Col>
            <Col className="text-center d-flex">
                <img src={ParksCanada} className="align-self-center m-auto" alt="Parks Canada" title="Parks Canada" />
            </Col>
            <Col className="text-center d-flex">
                <img
                    src={NaturalResources}
                    className="align-self-center m-auto"
                    alt="Ministry of natural resources"
                    title="Ministry of natural resources"
                />
            </Col>
            <Col className="text-center d-flex">
                <img src={Toronto} className="align-self-center m-auto" alt="City of Toronto" title="City of Toronto" />
            </Col>
            <Col className="text-center d-flex">
                <img
                    className="align-self-center m-auto"
                    src={Mississauga}
                    alt="City of Mississauga"
                    title="City of Mississauga"
                />
            </Col>
            <Col className="text-center d-flex">
                <img className="align-self-center m-auto" src={Peel} alt="Region of Peel" title="Region of Peel" />
            </Col>
            <Col className="text-center d-flex">
                <img
                    src={Frontenac}
                    className="align-self-center m-auto vertical"
                    alt="County of Frontenac"
                    title="County of Frontenac"
                />
            </Col>
            <Col className="text-center d-flex">
                <img src={GlobalMedic} className="align-self-center m-auto" alt="Global Medic" title="Global Medic" />
            </Col>
            <Col className="text-center d-flex">
                <img
                    src={Crawford}
                    className="align-self-center m-auto"
                    alt="Crawford & Company (Canada) Inc."
                    title="Crawford & Company (Canada) Inc."
                />
            </Col>
            <Col className="text-center d-flex">
                <img
                    className="align-self-center m-auto"
                    src={Uwaterloo}
                    alt="University of Waterloo"
                    title="University of Waterloo"
                />
            </Col>
            <Col className="text-center d-flex">
                <img
                    src={Sault}
                    className="align-self-center m-auto vertical"
                    alt="Sault College"
                    title="Sault College"
                />
            </Col>
            <Col className="text-center d-flex">
                <img src={Mohawk} className="align-self-center m-auto" alt="Mohawk College" title="Mohawk College" />
            </Col>
            <Col className="text-center d-flex">
                <img src={CBC} className="align-self-center m-auto" alt="CBC" title="CBC" />
            </Col>
            <Col className="text-center d-flex">
                <img
                    src={Enbridge}
                    className="align-self-center m-auto"
                    alt="Enbridge Energy"
                    title="Enbridge Energy"
                />
            </Col>
        </Row>
    </Container>
);

export default Companies;
