import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t } from "i18next";
import hero1 from "./../../Img/hero-3.jpg";
import hero2 from "./../../Img/hero-30.jpg";
// import hero3 from "./../../Img/hero-4.jpg";
import "./Hero.scss";

const Hero = () => (
    <Carousel controls={window.innerWidth < 767} className="c-hero" interval={8000}>
        <Carousel.Item style={{ backgroundImage: "url(" + hero2 + ")" }}>
            {/* <div className="angle-down d-none d-sm-block">
                <div className="right-side" />
            </div> */}
            <div className="overlay" />
            <Carousel.Caption>
                <h1 className="pb-3">{t("home_hero_1_h1")}</h1>
                <h2 className="pb-2 font-h5" dangerouslySetInnerHTML={{ __html: t("home_hero_1_h2") }} />
                <p className="font-h5" dangerouslySetInnerHTML={{ __html: t("home_hero_1_p") }} />
                <br className="d-none d-sm-block" />
                <Link className="btn btn-secondary" to="/courses">
                    {t("home_hero_1_cta")}
                </Link>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item style={{ backgroundImage: "url(" + hero1 + ")" }}>
            <div className="overlay" />
            <Carousel.Caption className="text-dark">
                <h1 className="pb-2">{t("home_hero_2_h1")}</h1>
                <h2 className="pb-2" dangerouslySetInnerHTML={{ __html: t("home_hero_2_h2") }} />
                <br />
                <p className="font-h5" dangerouslySetInnerHTML={{ __html: t("home_hero_2_p") }} />
                <p dangerouslySetInnerHTML={{ __html: t("home_hero_2_p2") }} />

                <br />
                <Link className="btn btn-secondary" to="/courses">
                    {t("home_hero_2_cta")}
                </Link>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
);

export default Hero;
