import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { t } from "i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./Mailchimp.scss";

const MailchimpForm = ({ status, message, onValidated }) => {
    let email;
    const submit = function(e) {
        e.preventDefault();
        if (email && email.value.indexOf("@") > -1) {
            onValidated({
                EMAIL: email.value
            });
        }
    };

    return (
        <div>
            <Form onSubmit={(e) => submit(e)}>
                {/* <Form.Group controlId="name" className="mb-2">
                    <Form.Control type="text" placeholder={t("mailchimp_name")} ref={node => (name = node)}/>
                </Form.Group> */}

                <Form.Group controlId="subscribeName" className="mb-2">
                    <Form.Label className="sr-only">{t("mailchimp_email")}</Form.Label>
                    <Form.Control type="email" placeholder={t("mailchimp_email")} ref={(node) => (email = node)} />
                    <Form.Text className="text-muted">{t("mailchimp_email_note")}</Form.Text>
                </Form.Group>

                <Button size="sm" variant="outline-primary" type="submit" className="px-3">
                    {t("mailchimp_submit")}
                </Button>
            </Form>
            <br />
            {status === "sending" && <div>{t("mailchimp_sending")}</div>}
            {status === "error" && <div> {t("mailchimp_submit_error")}</div>}
            {status === "success" && <div> {t("mailchimp_submit_ok")}</div>}
        </div>
    );
};

class Mailchimp extends Component {
    render() {
        const url =
            "https://ClarionDrones.us11.list-manage.com/subscribe/post?u=b79e28d02f3ba0138501d889b&amp;id=7b3a9ee301";
        return (
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <MailchimpForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />
                )}
            />
        );
    }
}

export default Mailchimp;
