import React from "react";
import { Card, Button } from "react-bootstrap";
import { t } from "i18next";
import "./BlogTeaser.scss";
import moment from "moment";

const BlogTeaser = (props) => {
    moment.locale(t.language);
    let dFormat = "MMM DD, YYYY";
    let timeStamp = moment.unix(props.blog.created).format(dFormat);

    return (
        <Card>
            {props.blog.attachmentfiles.length > 0 && props.blog.attachmentfiles[0].mimetype.indexOf("image") > -1 ? (
                <Card.Img alt={props.blog.subject} variant="top" src={props.blog.attachmentfiles[0].fileurl} />
            ) : null}
            <Card.Body className="pb-0">
                <Card.Title>{props.blog.subject}</Card.Title>
                <Card.Subtitle className="mb-3 text-muted">{timeStamp}</Card.Subtitle>
                <div
                    className="card-text description"
                    dangerouslySetInnerHTML={{
                        __html: props.blog.summary.substring(0, props.blog.summary.indexOf("</p>"))
                    }}
                />
            </Card.Body>
            <Card.Footer className="bg-light border-0">
                <Button href={`/blog/${props.blog.id}`}>{t("blog_read_more")}</Button>
            </Card.Footer>
        </Card>
    );
};

export default BlogTeaser;
