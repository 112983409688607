import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import i18next, { t } from "i18next";
import autoBind from "react-autobind";
import { Navbar, Nav, Container, Row, Col, NavDropdown, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
// Import full or requird bootstrap 4 scss - todo remove not required imports
import "./custom-bootstrap.scss";
import HomePage from "./Pages/Home";
import Footer from "./Components/Footer";
import "./main.scss";
import "./App.scss";
import logo from "./Img/logo.svg";
import loading from "./Img/loading.svg";

const CoursesPage = React.lazy(() => import("./Pages/Courses"));
const ErrorPage = React.lazy(() => import("./Pages/ErrorAll"));
const AboutPage = React.lazy(() => import("./Pages/About"));
const ContactPage = React.lazy(() => import("./Pages/Contact"));
const EventsPage = React.lazy(() => import("./Pages/Events"));
const TeachersPage = React.lazy(() => import("./Pages/Teachers"));
const TeacherDetailPage = React.lazy(() => import("./Pages/TeacherDetail"));
const BlogPage = React.lazy(() => import("./Pages/Blog"));
const BlogDetailPage = React.lazy(() => import("./Pages/BlogDetail"));

let GAId = "UA-72173384-1";
if (window.location.hostname === "localhost") {
    GAId = "UA-58346832-3";
    console.log("Switched to dev Google Analytics ID for localhost.");
}
ReactGA.initialize(GAId, {
    titleCase: false
});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
        autoBind(this);
    }

    toggleLanguage() {
        if (i18next.language === "es") {
            i18next.changeLanguage("en");
        } else {
            i18next.changeLanguage("es");
        }
        ReactGA.event({
            category: "Language",
            action: "Change",
            label: i18next.language
        });
    }

    toggleNavBar() {
        this.setState({
            expanded: false
        });
    }

    render() {
        return (
            <Router>
                <React.Fragment>
                    <div className="bg-primary top-bar">
                        <Container className="py-2">
                            <Row>
                                <Col>
                                    <a href="mailto:sales@clariondrones.com" className="text-light">
                                        <span>{t("menu_email")}</span>
                                    </a>
                                </Col>
                                <Col className="text-right text-light">
                                    <a href="https://lms.clariondrones.com" className="text-light">
                                        <span>{t("menu_login")}</span>
                                    </a>
                                    <Button
                                        variant="link"
                                        className="p-0 text-light d-none"
                                        onClick={this.toggleLanguage}>
                                        <span>{t("menu_language")}</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Navbar
                        bg="light"
                        expand="lg"
                        className="shadow-sm"
                        sticky="top"
                        expanded={this.state.expanded}
                        onToggle={() => {}}
                        collapseOnSelect>
                        <Container>
                            <Navbar.Brand>
                                <Link className="nav-link" to="/" onClick={this.toggleNavBar}>
                                    <img src={logo} alt="Clarion Drone Academy Inc." />
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle
                                aria-controls="main-navbar"
                                onClick={(e) => this.setState({ expanded: !this.state.expanded })}
                            />
                            <Navbar.Collapse id="main-navbar">
                                <Nav className="ml-auto">
                                    <Link className="nav-link" to="/" onClick={this.toggleNavBar}>
                                        {t("menu_home")}
                                    </Link>
                                    <Link className="nav-link" to="/courses" onClick={this.toggleNavBar}>
                                        {t("menu_courses")}
                                    </Link>
                                    <Link className="nav-link" to="/events" onClick={this.toggleNavBar}>
                                        {t("menu_events")}
                                    </Link>
                                    <Link className="nav-link" to="/teachers" onClick={this.toggleNavBar}>
                                        {t("menu_teachers")}
                                    </Link>
                                    <Link className="nav-link" to="/blog" onClick={this.toggleNavBar}>
                                        {t("menu_contact_blog")}
                                    </Link>

                                    {/*<NavDropdown title={t("menu_services")} id="nav-services">
                                        <Link className="dropdown-item" to="/services/1">
                                            {t("menu_services_1")}
                                        </Link>
                                        <Link className="dropdown-item" to="/services/2">
                                            {t("menu_services_2")}
                                        </Link>
                                        <Link className="dropdown-item" to="/services/3">
                                            {t("menu_services_3")}
                                        </Link>
                                    </NavDropdown>*/}

                                    <NavDropdown title={t("menu_more")} id="nav-contact">
                                        <Link className="dropdown-item" to="/about-us" onClick={this.toggleNavBar}>
                                            {t("menu_contact_about")}
                                        </Link>
                                        <Link className="dropdown-item" to="/contact-us" onClick={this.toggleNavBar}>
                                            {t("menu_contact_contact")}
                                        </Link>
                                    </NavDropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                    <React.Suspense
                        fallback={
                            <div className="text-center p-5" style={{ height: "50vh" }}>
                                <img
                                    src={loading}
                                    alt='{t("page_loading")}'
                                    style={{ width: "100px", marginTop: "10vh" }}
                                />
                            </div>
                        }>
                        <Switch>
                            <Route path="/" exact render={() => <HomePage />} />
                            <Route path="/courses" exact render={() => <CoursesPage />} />
                            <Route path="/events" render={() => <EventsPage />} />
                            <Route path="/about-us" render={() => <AboutPage />} />
                            <Route path="/contact-us" render={() => <ContactPage />} />
                            <Route path="/teachers" exact render={() => <TeachersPage />} />
                            <Route path={`/teachers/:uid`} render={(props) => <TeacherDetailPage {...props} />} />
                            <Route path="/blog" exact render={() => <BlogPage />} />
                            <Route path={`/blog/:id`} render={(props) => <BlogDetailPage {...props} />} />
                            <Route render={() => <ErrorPage />} />
                        </Switch>
                    </React.Suspense>
                    <Footer />
                    {/*Pre download image on the page so it's not loaded when suspense called */}
                    <img src={loading} alt="Loading..." style={{ display: "none" }} />
                </React.Fragment>
            </Router>
        );
    }
}

export default withTranslation()(App);
