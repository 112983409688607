import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Social.scss";
import twitter from "./../../Img/twitter.svg";
import linkedin from "./../../Img/linkedin.svg";
import youtube from "./../../Img/youtube.svg";

const Social = () => (
    <div>
        <Row>
            <Col xs="auto" className="mb-1">
                <a href="https://ca.linkedin.com/in/bruce-mcpherson-b528b45">
                    <img src={linkedin} alt="LinkedIn" width="30px" height="30px" />
                </a>
            </Col>
            <Col xs="auto" className="mb-1">
                <a href="https://twitter.com/clariondrones">
                    <img src={twitter} alt="Twitter" width="30px" height="30px" />
                </a>
            </Col>
            <Col xs="auto" className="mb-1">
                <a href="https://www.youtube.com/channel/UCkSFMGqxQ7SwA8miWc3A5bA">
                    <img src={youtube} alt="YouTube" width="30px" height="30px" />
                </a>
            </Col>
        </Row>
    </div>
);

export default Social;
