import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t } from "i18next";
import Mailchimp from "./../Mailchimp";
import Social from "./../Social";

import "./Footer.scss";
import logo from "./../../Img/logo.svg";

const thisYear = new Date().getFullYear();

const Footer = () => (
    <footer className="mt-5">
        <Container className="pt-4 pb-2">
            <Row className="pt-4">
                <Col>
                    <div>
                        <div>
                            <img src={logo} className="mb-2" alt="Clarion Drone Academy Inc." height="75px" />
                        </div>
                        <div className="border-top py-2 mt-2">
                            {t("footer_address_line1")}
                            <br aria-hidden="true" />
                            {t("footer_address_line2")}
                            <br aria-hidden="true" />
                            {t("footer_address_line3")}
                            <br aria-hidden="true" />
                            <div className="mt-1">
                                <a href="tel:+15194985259">1 (519) 498 5259</a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <h5>Helpful links</h5>
                    <ul className="list-unstyled">
                        <li className="pb-1 pl-2">
                            <Link to="/blog">{t("menu_contact_blog")}</Link>
                        </li>
                        <li className="pb-1 pl-2">
                            <a href="https://lms.clariondrones.com">{t("footer_login_link")}</a>
                        </li>
                        <li className="pb-1 pl-2">
                            <Link to="/contact-us">{t("menu_contact_contact")}</Link>
                        </li>
                        <li className="p-2 mt-2">
                            <Social />
                        </li>
                    </ul>
                </Col>
                <Col sm>
                    <h5>{t("mailchimp_heading")}</h5>
                    <Mailchimp />
                </Col>
            </Row>
        </Container>
        <div className="p-2 _bg-light text-center">
            <Container>
                <Row>
                    <Col>
                        <small>{t("footer_copyright", { year: thisYear })}</small>
                    </Col>
                </Row>
            </Container>
        </div>
    </footer>
);

export default Footer;
