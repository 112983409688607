import React, { Component } from "react";
import { t } from "i18next";
import "./Home.scss";
import Hero from "./../../Components/Hero";
import Testimonials from "./../../Components/Testimonials";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import Step1 from "./../../Img/online-training.jpg";
import Step2 from "./../../Img/online-exam.jpg";
import Step3 from "./../../Img/start-flying.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import Blog from "../Blog";
import utils from "../../utils";
import Companies from "../../Components/Companies";

class HomePage extends Component {
    componentDidMount() {
        ReactGA.pageview("/");
        utils.scrollTo("root");
        utils.updatePageTitle(t("footer_brand_name"));
    }
    render() {
        return (
            <main className="p-home">
                <Hero />
                <Companies />
                <div className="contents how shadow-sm">
                    <Container className="py-5 pt-4">
                        <Row>
                            <Col className="text-center">
                                <h2 className="text-primary text-uppercase">{t("home_easy_as_123")}</h2>
                            </Col>
                        </Row>

                        <Row className="pb-4">
                            <Col md className="row-eq-height mt-4">
                                <ScrollAnimation animateIn="bounceInUp" animateOnce={true}>
                                    <Card className="border-0 shadow">
                                        <Card.Img variant="top" src={Step1} />
                                        <Card.Body>
                                            <Card.Title>{t("home_easy_as_col_1")}</Card.Title>
                                            <Card.Text
                                                dangerouslySetInnerHTML={{
                                                    __html: t("home_easy_as_col_1_description")
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </ScrollAnimation>
                            </Col>
                            <Col md className="row-eq-height mt-4">
                                <ScrollAnimation animateIn="bounceInUp" animateOnce={true}>
                                    <Card className="border-0 shadow">
                                        <Card.Img variant="top" src={Step2} />
                                        <Card.Body>
                                            <Card.Title>{t("home_easy_as_col_2")}</Card.Title>
                                            <Card.Text
                                                dangerouslySetInnerHTML={{
                                                    __html: t("home_easy_as_col_2_description")
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </ScrollAnimation>
                            </Col>
                            <Col md className="row-eq-height mt-4">
                                <ScrollAnimation animateIn="bounceInUp" animateOnce={true}>
                                    <Card className="border-0 shadow">
                                        <Card.Img variant="top" src={Step3} />
                                        <Card.Body>
                                            <Card.Title>{t("home_easy_as_col_3")}</Card.Title>
                                            <Card.Text
                                                dangerouslySetInnerHTML={{
                                                    __html: t("home_easy_as_col_3_description")
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="text-center">
                                <Link className="btn btn-outline-primary" to="/courses">
                                    {t("home_easy_as_CTA")}
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="contents py-5 mt-4">
                    <Row>
                        <Col className="text-center">
                            <h2 className="text-primary text-uppercase">{t("home_what_we_do")}</h2>
                            <div
                                className="pt-3"
                                dangerouslySetInnerHTML={{
                                    __html: t("home_what_we_do_description")
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="pt-5">
                        <Col md className="row-eq-height">
                            <ScrollAnimation animateIn="bounceInUp" animateOnce={true} className="mt-2 mt-md-0">
                                <Card className="shadow-sm border-light mb-3 mb-md-0">
                                    <Card.Body className="text-center p-3">
                                        <Card.Title
                                            dangerouslySetInnerHTML={{
                                                __html: t("home_what_we_do_1")
                                            }}
                                        />
                                        <Card.Text
                                            className="text-justify"
                                            dangerouslySetInnerHTML={{
                                                __html: t("home_what_we_do_1_description")
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </ScrollAnimation>
                        </Col>
                        <Col md className="row-eq-height">
                            <ScrollAnimation animateIn="bounceInUp" animateOnce={true} className="mt-2 mt-md-0">
                                <Card className="shadow-sm border-light mb-3 mb-md-0">
                                    <Card.Body className="text-center p-3">
                                        <Card.Title
                                            dangerouslySetInnerHTML={{
                                                __html: t("home_what_we_do_2")
                                            }}
                                        />
                                        <Card.Text className="text-justify">
                                            {t("home_what_we_do_2_description")}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </ScrollAnimation>
                        </Col>
                        <Col md className="row-eq-height">
                            <ScrollAnimation animateIn="bounceInUp" animateOnce={true} className="mt-2 mt-md-0">
                                <Card className="shadow-sm border-light mb-3 mb-md-0">
                                    <Card.Body className="text-center p-3">
                                        <Card.Title
                                            dangerouslySetInnerHTML={{
                                                __html: t("home_what_we_do_3")
                                            }}
                                        />
                                        <Card.Text className="text-justify">
                                            {t("home_what_we_do_3_description")}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
                <Testimonials />
                <Blog max={3} />
            </main>
        );
    }
}

export default HomePage;
// export default withTranslation()(HomePage);
