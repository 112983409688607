const utils = {
    timeout(promise, milliseconds = 20000) {
        return new Promise(function(resolve, reject) {
            promise.then(resolve, reject);
            setTimeout(reject.bind(null, new Error("Timed Out!")), milliseconds);
        });
    },

    makeGetRequest(dataURL, postData, returnJSON = false) {
        let headers = {
            method: "GET"
        };

        if (postData) {
            headers = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postData)
            };
        }
        const req = new Request(dataURL, headers);

        return utils.timeout(
            fetch(req)
                .then((response) => {
                    if (!response.ok) {
                        return response.reject(response.status);
                    } else if (returnJSON) {
                        return response.json();
                    } else {
                        return response.text();
                    }
                })
                .catch((error) => {
                    throw error;
                })
        );
    },

    // Pass class or ID
    scrollTo(ele) {
        const navbarHeight = document.getElementsByTagName("nav")[0].offsetHeight;
        let scrollElement =
            document.getElementById(ele) ||
            (document.getElementsByClassName(ele) && document.getElementsByClassName(ele)[0]);
        if (scrollElement) {
            window.scrollTo(0, scrollElement.offsetTop - navbarHeight - 30);
        }
    },

    getShortDescription(desc, maxLength, all) {
        if (desc.length <= maxLength || all) {
            return desc;
        }

        let pindex = desc.indexOf("</p>");
        let returnMarkup = "";
        // Only check upto second paragraph.
        if (pindex < maxLength) {
            pindex = desc.indexOf("</p>", pindex + 1);
        }
        if (pindex > maxLength + 4) {
            // Next paragraph too far, trim and add closing tag.
            returnMarkup = desc.substring(0, maxLength - 8) + "...</p>";
        }
        // Trim and get contents upto specified length.
        returnMarkup = returnMarkup.substring(0, maxLength);

        return returnMarkup;
    },

    updatePageTitle(pTitle, imageURL) {
        document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);

        if (pTitle) {
            document.title = pTitle; // page title
            document.querySelector('meta[property="og:title"]').setAttribute("content", pTitle);
            document.querySelector('meta[name="twitter:title"]').setAttribute("content", pTitle);
        }
        if (imageURL) {
            document.querySelector('meta[property="og:image"]').setAttribute("content", imageURL);
            document.querySelector('meta[name="twitter:image"]').setAttribute("content", imageURL);
        } else {
            // reset
            document
                .querySelector('meta[property="og:image"]')
                .setAttribute("content", "https://www.clariondrones.com/logo.jpg");
            document
                .querySelector('meta[name="twitter:image"]')
                .setAttribute("content", "https://www.clariondrones.com/logo.jpg");
        }
    },

    randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    },

    minutesFormatted(min) {
        let hours = min / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        let output = "";

        if (rhours > 0) {
            output += rhours + " hr";
            if (rhours > 1) {
                output += "s";
            }
            output += " ";
        }

        if (rminutes > 0) {
            output += rminutes + " min";
            if (rminutes > 1) {
                output += "s";
            }
        }
        return output;
    }
};

export default utils;
